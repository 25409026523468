<template>
  <div
    id="login"
    :class="{ recaptcha: recaptcha }"
    @click="info ? (info = false) : null"
  >
    <div id="scene" class="scene" ref="scene">
      <div
        :class="{ layer: true, active: focus }"
        data-depth="0.25"
        style="background-image: url('/static/img/back4.jpg')"
      ></div>
      <div
        class="layer"
        data-depth="0.15"
        style="background-image: url('/static/img/back3.png')"
      ></div>
      <div
        class="layer"
        data-depth="0.10"
        style="background-image: url('/static/img/back2.png')"
      ></div>
      <div
        class="layer"
        data-depth="0.00"
        style="background-image: url('/static/img/back1.png')"
      ></div>
    </div>
    <form @submit="submit">
      <img :src="logoURL" alt="File Browser" />
      <h1>{{ name }}</h1>
      <div v-if="error !== ''" class="wrong">{{ error }}</div>

      <input
        class="input input--block"
        type="text"
        v-model="username"
        :placeholder="$t('login.username')"
        @focus="focus = true"
        @blur="focus = false"
      />
      <input
        class="input input--block"
        type="password"
        v-model="password"
        :placeholder="$t('login.password')"
        @focus="focus = true"
        @blur="focus = false"
      />
      <input
        class="input input--block"
        v-if="createMode"
        type="password"
        v-model="passwordConfirm"
        :placeholder="$t('login.passwordConfirm')"
      />

      <div v-if="recaptcha" id="recaptcha"></div>
      <input
        class="button button--block"
        type="submit"
        :value="createMode ? $t('login.signup') : $t('login.submit')"
      />

      <p @click="toggleMode" v-if="signup">
        {{
          createMode ? $t("login.loginInstead") : $t("login.createAnAccount")
        }}
      </p>
    </form>
    <div id="open-info" :class="{ active: info }">
      <button id="" @click.stop="info = !info">i</button>
      <div v-if="$i18n.locale === 'ru'">Информация</div>
      <div v-else>Info</div>
    </div>
    <div id="modal-info" :class="{ active: info }">
      <button @click.stop="info = !info" class="close">
        <i class="material-icons">close</i>
      </button>
      <div
        v-if="$i18n.locale === 'ru'"
        class="modal-content"
        @click.stop="null"
      >
        <h2>Универсальный облачный сервис для фотоловушек.</h2>
        <p>
          Технологическая платформа &laquo;Трейлкам.ру&raquo; обеспечивает
          оперативный контроль за поступающими сообщениями с фотоловушек,
          доставку полноформатных фото и видео (в случае поддержки данной
          функцией фотоловушкой) непосредственно в мессенджер, минуя почтовые
          программы, работу с несколькими камерами в рамках одной учётной
          записи, облачное хранилище материалов, пересылку фото и видео на ваш
          основной e-mail.
        </p>
        <p>
          Наш сервис работает с фотоловушками любых производителей, в том числе
          осуществляет приём сообщений от камер, не поддерживающих шифрование.
        </p>
        <p>
          Поделитесь фотографиями со своими друзьями напрямую из мессенджера или
          облачного хранилища!
        </p>
        <p>
          Забудьте про проблемы с настройками почты и постоянно изменяющимися
          правилами почтовых сервисов. Используйте
          <a href="https://trailcam.ru">Trailcam.ru</a>!
        </p>
        <h3>
          Подключиться к сервису можно через нашего telegram бота —
          <a href="https://t.me/trailcamru_bot">@trailcamru_bot</a>
        </h3>
        <p>
          Написать в техническую поддержу —
          <a :href="'mailto:' + mailto">{{ mailto }}</a>
        </p>
        <p>
          Наш новостной канал —
          <a href="https://t.me/trailcamru">@trailcamru</a>
        </p>
        <blockquote>
          Удачных вам фотографий!<br />
          Команда <a href="https://trailcam.ru">Trailcam.ru</a>
        </blockquote>
      </div>
      <div v-else class="modal-content" @click.stop="null">
        <h2>Universal cloud service for trail cameras</h2>
        <p>
          Trailcam.ru platform allow you to receive all messages from your trail
          cameras in real time, photos and videos delivery directly to your
          messenger on smartphone. No any email clients needed anymore. We
          provide multiple cameras access within single account, cloud media
          storage, message forward to any e-mail account.
        </p>
        <p>
          Our service compatible with all well-known trail camera models and can
          work with trail cameras which not supporting SSL authentication.
        </p>
        <p>
          Share your photos with friends directly from your messenger or cloud
          storage.
        </p>
        <p>
          Forget all problems with e-mail setup and their antispam rules - just
          use <a href="https://trailcam.ru">Trailcam.ru</a>
          cloud service!
        </p>
        <h3>
          Join us via our telegram bot —
          <a href="https://t.me/trailcamru_bot">@trailcamru_bot</a>
        </h3>
        <p>
          Technical support — <a :href="'mailto:' + mailto">{{ mailto }}</a>
        </p>
        <p>News channel — <a href="https://t.me/trailcamru">@trailcamru</a></p>
        <blockquote>
          Enjoy your trailcam experience!<br />
          <a href="https://trailcam.ru">Trailcam.ru</a> team.
        </blockquote>
      </div>
    </div>
    <div id="contacts" v-if="$i18n.locale === 'ru'">
      &copy; 2021 Trailcam.ru | 
      <a href="/oferta/index.html?v1" target="_blank">Публичная оферта</a> | 
      <a href="/policy/index.html?v1" target="_blank">Политика конфиденциальности</a>
    </div>
    <div id="contacts" v-else>&copy; 2021 Trailcam.ru</div>
  </div>
</template>

<script>
import * as auth from "@/utils/auth";
import {
  name,
  logoURL,
  recaptcha,
  recaptchaKey,
  signup,
} from "@/utils/constants";
import Parallax from "parallax-js";

export default {
  name: "login",
  computed: {
    signup: () => signup,
    name: () => name,
    logoURL: () => logoURL,
    token: function () {
      return this.$route.query.token;
    },
  },
  data: function () {
    return {
      createMode: false,
      error: "",
      username: "",
      password: "",
      recaptcha: recaptcha,
      passwordConfirm: "",
      parallax: null,
      mailto: window.atob("c3VwcG9ydEB0cmFpbGNhbS5ydQ=="),
      info: false,
      focus: false,
    };
  },
  mounted() {
    if (this.token) {
      return window.setTimeout(() => this.auth(this.token), 500);
    }
    this.parallax = new Parallax(this.$refs.scene);
    if (!recaptcha) return;
    window.grecaptcha.ready(function () {
      window.grecaptcha.render("recaptcha", {
        sitekey: recaptchaKey,
      });
    });
  },
  beforeDestroy() {
    if (this.parallax) {
      this.parallax.destroy();
    }
  },
  methods: {
    toggleMode() {
      this.createMode = !this.createMode;
    },
    async submit(event) {
      event.preventDefault();
      event.stopPropagation();

      let redirect = this.$route.query.redirect;
      if (redirect === "" || redirect === undefined || redirect === null) {
        redirect = "/files/";
      }

      let captcha = "";
      if (recaptcha) {
        captcha = window.grecaptcha.getResponse();

        if (captcha === "") {
          this.error = this.$t("login.wrongCredentials");
          return;
        }
      }

      if (this.createMode) {
        if (this.password !== this.passwordConfirm) {
          this.error = this.$t("login.passwordsDontMatch");
          return;
        }
      }

      try {
        if (this.createMode) {
          await auth.signup(this.username, this.password);
        }

        await auth.login(this.username, this.password, captcha);
        this.$router.push({ path: redirect });
      } catch (e) {
        if (e.message == 409) {
          this.error = this.$t("login.usernameTaken");
        } else {
          this.error = this.$t("login.wrongCredentials");
        }
      }
    },
    async auth(token) {
      try {
        await auth.loginToken(token);
        this.$router.push({ path: "/files/" });
      } catch (e) {
        this.error = this.$t("login.wrongCredentials");
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>
